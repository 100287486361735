import {Property} from "csstype";

export const underline = `
    text-decoration: underline;
`;

export const textAlign = (align: Property.TextAlign) => `
    text-align: ${align};
`;
import {Property} from "csstype";

export const underline = `
    text-decoration: underline;
`;

export const textAlign = (align: Property.TextAlign) => `
    text-align: ${align};
`;
